import { render, staticRenderFns } from "./dealerRoutesDetail.vue?vue&type=template&id=c49d03d8&"
import script from "./dealerRoutesDetail.vue?vue&type=script&lang=js&"
export * from "./dealerRoutesDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
installComponents(component, {VCard,VCardText,VDivider,VFlex,VLayout,VProgressLinear})
