<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard title="Ruta de reparto"> </titleCard>
        <v-divider></v-divider>
        <v-progress-linear v-if="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
        <template v-if="!loading">
          <v-card-text>
            <v-layout row wrap text-xs-left>
              <v-flex xs3>
                <h6 ref="h6" class="title mb-2">Repartidor</h6>
                <p v-if="item.car">
                  Nombre: {{ item.dealerInfo.name }} <br />
                  Rut: {{ item.dealerInfo.rut }}
                </p>
              </v-flex>
              <v-flex xs3>
                <h6 class="title mb-2">Vehiculo</h6>
                <p v-if="item.car">
                  Patente: {{ item.car.patente }} <br />
                  Marca: {{ item.car.marca }} {{ item.car.modelo }}
                </p>
              </v-flex>
              <v-flex xs3>
                <h6 class="title mb-2">Ruta</h6>
                <p v-if="item.start && item.end">
                  Inicio: {{ item.start.date_ }}<br />
                  Fin: {{ item.end.date_ }}
                </p>
              </v-flex>
              <v-flex xs3>
                <h6 class="title mb-2">Pedidos</h6>
                <template v-for="(order, e) in item.orders">
                  <div :key="e">
                    <router-link :key="index" target="_blank" :to="{ name: 'orderIndex', params: { id: order.id } }">
                      {{ order.id }}
                    </router-link>
                  </div>
                </template>
                {{ currentMidx }}
              </v-flex>
            </v-layout>
          </v-card-text>
          <gmap-map
            id="map"
            ref="myMap"
            :options="options"
            :center="center"
            :zoom="zoom"
            :style="`width:100%;height:50vh;`"
          >
            <template v-for="(marker, index) in item.latLng" v-if="item.latLng">
              <GmapMarker
                :key="`GPS-${index}`"
                :options="optionsMarkerOrder(index)"
                :position="{ lat: marker.latitude, lng: marker.longitude }"
                @click="openDetail({ lat: marker.latitude, lng: marker.longitude }, marker.date, index)"
              />
            </template>
            <template v-for="(o, index) in item.orders" v-if="item.orders">
              <GmapMarker
                :key="`GPS-${index}`"
                :options="{
                  icon: '/img/normal.png',
                  opacity: 1
                }"
                :position="{ lat: o.latitude, lng: o.longitude }"
                @click="openDetaiOrder({ lat: o.latitude, lng: o.longitude }, o, index)"
              />
            </template>
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="closeInfoWindow()"
            />
          </gmap-map>
        </template>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { MI_STORE } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'DealerRoutes',
  components: {
    titleCard
  },
  data() {
    return {
      dealer: 1,
      item: [],
      center: {
        lat: -33.4262,
        lng: -70.6429
      },
      zoom: 12,
      paths: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      loading: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    cars() {
      return this.$store.getters.getCars
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.dealer = parseInt(this.$route.params.id)
    }
    this.getInfo()
  },
  methods: {
    optionsMarkerOrder(index) {
      const options = {
        icon: this.currentMidx === index ? '/img/estoy-aqui.png' : '',
        opacity: this.currentMidx === index ? 1 : 0.05
      }
      return options
    },
    testMap() {
      const paths = this.item.latLng
      const _paths = []
      paths.forEach(e => {
        _paths.push([e.longitude, e.latitude])
      })
      this.$refs.myMap.$mapPromise.then(map => {
        // eslint-disable-next-line no-undef
        let myCityData = new google.maps.Data()
        myCityData.addGeoJson(
          {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: _paths
                },
                id: 'city'
              }
            ]
          },
          'city'
        )
        myCityData.setStyle({
          strokeColor: '#7c4dff',
          fillOpacity: 1,
          strokeWeight: 5
        })
        myCityData.setMap(map)
      })
    },
    openDetail(latLng, content, idx) {
      this.infoOptions.content = `${this.$moment(content).format('HH:mm:ss')} hrs`
      this.infoWindowPos = {
        lat: latLng.lat,
        lng: latLng.lng
      }
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    openDetaiOrder(latLng, o, idx) {
      this.infoOptions.content = `
      Pedido ${o.id}<br>
      <br>
      ${o.name}<br>
      ${o.address}<br>
      ${o.schedule}<br>
      <br>
      <a target="_blank" href="/pedido/${o.id}/">Ir al pedido</a>`
      this.infoWindowPos = {
        lat: latLng.lat,
        lng: latLng.lng
      }
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    closeInfoWindow() {
      this.infoWinOpen = false
      this.currentMidx = null
    },
    async getInfo() {
      try {
        const res = await this.$http.get(`${MI_STORE}/routes/${this.dealer}`)
        if (res.data.routes) {
          this.item = res.data.routes[0]
        }
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: error
        })
      }
      setTimeout(() => {
        this.testMap()
      }, 500)
    }
  }
}
</script>
